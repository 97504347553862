html, body, #root, #root>div {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "Black Delights Font";
  src: url("./assets/Black\ Delights.ttf") format("truetype"),
    url("./assets/Black\ Delights.woff") format("woff"),
    url("./assets/Black\ Delights.otf") format("opentype");
  font-weight:normal;
}
