.App {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url("./assets/backgroundcover.png") no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  font-family: "Black Delights Font", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
}

h1 {
  font-size: 4rem;
  letter-spacing: 4px;
}

.App-logo {
  position: absolute;
  bottom: 20px;
  right: 20px;
}